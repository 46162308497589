import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import FutureLayout from 'layouts/FutureLayout';
import SpotLayout from 'layouts/SpotLayout';

import SpotPosition from 'pages/tribot/spot/position';
import SpotTrade from 'pages/tribot/spot/trade';

import FutureTrade from 'pages/tribot/future/trade';
import FuturePositions from 'pages/tribot/future/positions';
import FutureBybitPositions from 'pages/tribot/future/bybitPositions';

import Trade from 'pages/trade';

import Toast from './components/common/Toast';
import './index.css';

const darkTheme = createTheme({ palette: { mode: 'dark' } });
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route element={<FutureLayout />}>
            <Route path="tribot/future" element={<FutureTrade />} />
            <Route path="tribot/future/position" element={<FuturePositions />} />
            <Route path="tribot/future/bybit/position" element={<FutureBybitPositions />} />
          </Route>

          <Route element={<SpotLayout />}>
            <Route path="tribot/spot" element={<SpotTrade />} />
            <Route path="tribot/spot/upbit/position" element={<SpotPosition />} />
            <Route path="tribot/spot/bithumb/position" element={<SpotPosition />} />
          </Route>

          <Route path="trade" element={<Trade />} />

          <Route
            path="*"
            element={
              <main style={{ padding: '1rem' }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
      <Toast />
    </ThemeProvider>
  </React.StrictMode>
);
