import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

import UpbitClient from 'core/tribot/upbit/client';
import Orders from 'components/trade/Orders';
import Positions from 'components/trade/Positions';

const Home = () => {
  const [ticker, setTicker] = useState({ symbol: '', markPrice: '' });
  const [amt, setAmt] = useState(0);

  useEffect(() => {
    asyncFetch();
  }, []);

  async function asyncFetch() {
    const tickers = await UpbitClient.getTickers();
    const filteredList = tickers.filter((item) => item.symbol === 'KRW-BTC');
    setTicker(filteredList[0]);
  }

  const onChangeAmt = (e) => {
    setAmt(e.target.value);
  };

  const clickBuy = () => {
    console.log('amt :' + amt);
    console.log('symbol :' + ticker.symbol);
  };

  return (
    <div className="text-white">
      <div className="text-xs">
        {ticker.symbol}, {ticker.markPrice},
      </div>
      <Input className="w-20" onChange={onChangeAmt} value={amt} />
      <Button variant="outlined" onClick={() => clickBuy()}>
        <span className="text-xs text-red-500">Market Buy</span>
      </Button>

      <div>
        {/* Group1
        <div>
          Orders
          <div>
            if ema 15 up, 1000000, Buy
            <Button variant="outlined">
              <span className="text-xs text-red-500">remove</span>
            </Button>
          </div>
        </div> */}
        {/* <Orders /> */}
        <Positions ticker={ticker} />
      </div>
    </div>
  );
};

export default Home;
