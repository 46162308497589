import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

export default function PositionMenu(props) {
  const [model, setModel] = useState({
    type: 'Buy',
    price: 0,
    amt: 0.01,
    group: 0,
    date: '',
  });
  //   const [amt, setAmt] = useState(0);

  useEffect(() => {
    setModel((prevModel) => ({
      ...prevModel,
      price: props.markPrice,
    }));
  }, [props.markPrice]);

  const onChangeAmt = (e) => {
    setModel((prevModel) => ({
      ...prevModel,
      amt: e.target.value,
    }));
  };

  const onChangePrice = (e) => {
    setModel((prevModel) => ({
      ...prevModel,
      price: e.target.value,
    }));
  };

  const postModelData = async () => {
    const url = 'https://tradeapi.suseona.com/trade/positions';
    console.log('model', model);
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(model), // 모델 데이터를 JSON으로 변환하여 전송
      });

      const result = await response.json();
      location.reload();
    } catch (error) {
      console.error('Failed to post data:', error);
    }
  };

  const clickBuy = () => {
    setModel((prevModel) => ({
      ...prevModel,
      type: 'Buy',
    }));
  };

  const clickSell = () => {
    setModel((prevModel) => ({
      ...prevModel,
      type: 'Sell',
    }));
  };

  return (
    <div className="my-4 ml-3">
      <div className="my-4 flex">
        <div>
          <Button
            variant={model.type === 'Buy' ? 'outlined' : ''}
            onClick={() => {
              clickBuy();
            }}
          >
            <span className="text-xs text-green-400">Buy</span>
          </Button>
        </div>
        <div className="ml-3">
          <Button variant={model.type === 'Sell' ? 'outlined' : ''} onClick={() => clickSell()}>
            <span className="text-xs text-red-500">Sell</span>
          </Button>
        </div>
        <Input className="ml-5 w-28" onChange={onChangePrice} value={model.price} />
        <Input className="ml-5 w-28" onChange={onChangeAmt} value={model.amt} />

        <div className="ml-3">
          <Button variant="outlined" onClick={() => postModelData()}>
            <span className="text-xs text-white">ADD</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
