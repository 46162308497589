import { useEffect, useState, useRef, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import PositionMenu from 'components/trade/PositionMenu';

function sumBuyAmount(data) {
  const result = data
    .filter((item) => item.type === 'Buy') // Buy 타입만 필터링
    .reduce((acc, item) => acc + item.amt, 0); // amt의 합계 계산
  return result.toFixed(4);
}

function sumSellAmount(data) {
  const result = data
    .filter((item) => item.type === 'Sell') // Buy 타입만 필터링
    .reduce((acc, item) => acc + item.amt, 0); // amt의 합계 계산
  return result.toFixed(4);
}

function calculateProfitRate(marketPrice, averageBuyPrice) {
  // Calculate profit rate using the formula
  const profitRate = ((marketPrice - averageBuyPrice) / averageBuyPrice) * 100;

  // Return the rounded profit rate
  return profitRate.toFixed(2);
}

function calculateTradingStats(transactions) {
  let totalPosition = 0; // Accumulated position (amount of assets)
  let totalCost = 0; // Total cost (for the remaining position)
  let totalSpent = 0; // Total amount spent on purchases (for profit rate calculation)
  let totalProfit = 0; // Accumulated realized profit

  const results = transactions.map((transaction) => {
    const { type, price, amt, date } = transaction;

    if (type === 'Buy') {
      const totalAmtSpent = price * amt; // Calculate total cost of purchase
      totalPosition += amt;
      totalCost += totalAmtSpent; // Add the total cost of the purchase
      totalSpent += totalAmtSpent; // Add the amount spent for profit rate calculation
    } else if (type === 'Sell') {
      const totalAmtSold = price * amt; // Calculate total sale amount
      const averagePrice = totalCost / totalPosition; // Average cost price per unit of position

      // Calculate realized profit: sale amount - cost of sold position
      const realizedProfit = totalAmtSold - amt * averagePrice;
      totalProfit += realizedProfit; // Add realized profit from this sale

      // Update position and total cost
      totalPosition -= amt;
      totalCost = totalPosition * averagePrice; // Recalculate total cost for remaining position
    }

    // Calculate real-time average price (for remaining position)
    const averagePrice = totalPosition ? totalCost / totalPosition : 0;

    // Calculate accumulated profit rate based on total spent
    const profitRate = totalSpent > 0 ? (totalProfit / totalSpent) * 100 : 0;

    // Return the result including original transaction data
    return {
      ...transaction,
      averagePrice: averagePrice.toFixed(0),
      accumulatedProfit: totalProfit.toFixed(0),
      accumulatedProfitRate: profitRate.toFixed(2) + '%',
    };
  });

  return results; // Return the array with original data and results
}

export default function Positions(props) {
  const [rowData, setRowData] = useState([]);

  const [columnDefs] = useState([
    { field: 'group', headerName: 'G', width: 36 },
    { field: 'type', headerName: 'type', width: 60 },
    { field: 'price', headerName: 'price', width: 90, sortable: true },
    { field: 'amt', headerName: 'amt', width: 90, sortable: true },

    { field: 'averagePrice', headerName: 'avePrice', width: 100 },
    { field: 'accumulatedProfit', headerName: 'Profit', width: 80 },
    { field: 'accumulatedProfitRate', headerName: 'Rate', width: 70 },

    { field: 'date', headerName: 'date', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRendererFramework: (params) => (
        <button onClick={() => handleDelete(params)} style={{ cursor: 'pointer' }}>
          Delete
        </button>
      ),
    },
  ]);

  useEffect(() => {
    console.log(props.ticker);
    getData();
  }, []);

  const handleDelete = useCallback((params) => {
    const idToDelete = params.data.id;
    deleteData(idToDelete);
    setRowData((prevRowData) => prevRowData.filter((row) => row.id !== idToDelete));
  }, []);

  const getData = async () => {
    const url = 'https://tradeapi.suseona.com/trade/positions';
    try {
      const response = await fetch(url);
      const result = await response.json();

      result.results.sort(function (a, b) {
        return b.id - a.id;
      });

      const temp = calculateTradingStats(result.results);
      setRowData(temp);
    } catch (error) {
      console.error('Failed to post data:', error);
    }
  };

  const deleteData = async (id) => {
    const url = 'https://tradeapi.suseona.com/trade/positions/' + id;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Failed to post data:', error);
    }
  };

  return (
    <div className="mt-4">
      Positions
      <PositionMenu markPrice={props.ticker.markPrice} />
      <div className="ml-4">
        Total : {sumBuyAmount(rowData)} / {sumSellAmount(rowData)}
      </div>
      <div className="ml-4">
        ProfitRate :{' '}
        {calculateProfitRate(props.ticker.markPrice, rowData[rowData.length - 1]?.averagePrice)}%
      </div>
      <div className="ml-4"></div>
      <div className="ag-theme-balham-dark h-">
        <AgGridReact rowData={rowData} columnDefs={columnDefs} domLayout="autoHeight"></AgGridReact>
      </div>
    </div>
  );
}
